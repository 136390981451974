<template>
  <div class="text-center m-auto w-650">
    <h3>Das Dashboard</h3>
    <p class="pt-3 pb-5">
      Das Suchergebnis gibt unter anderem Auskunft über Produktinformationen,
      eigene Lieferbestände und die des Lieferanten sowie aktuelle,
      vertrauenswürdige Lieferzeiten des Lieferanten.
    </p>
    <img src="@/assets/images/Tutorial_1.png" />
  </div>
</template>

<script>
export default {
  name: "TutorialTwo",
};
</script>

<style lang="scss" scoped>
img {
  height: 34vh;
}
</style>