<template>
  <div class="text-center m-auto w-650">
    <h3>Grundfunktion</h3>
    <p class="pt-3 pb-5">
      Mit einem Scan des QR-Codes oder alternativ der Eingabe des
      Herstellernamens, Produktnamens oder der EAN ganz einfach den ausgewählten
      Artikel finden.
    </p>
    <img src="@/assets/images/Tutorial_1.png" />
  </div>
</template>

<script>
export default {
  name: "TutorialOne",
};
</script>

<style lang="scss" scoped>
img {
  height: 34vh;
}
</style>