<template>
  <div class="text-center m-auto w-650 mt-auto">
    <h3>Herzlich Willkommen bei VME Product Info!</h3>
    <p class="pt-3 pb-5">
      Diese BETA-Testphase wird permanent erweitert – Auskünfte zu Lieferzeiten,
      Lagerbeständen und Produktinformationen werden einfach über die
      Suchfunktion oder den QR-Code des Produkts abgerufen.
    </p>
  </div>
</template>

<script>
export default {
  name: "TutorialStart",
};
</script>