<template>
  <div class="bg-primary vh-100 p-5 d-flex flex-column overflow-auto">
    <div class="logo">
      <img src="@/assets/icons/Logo.svg" />
    </div>
    <div
      v-if="ifPassedTutorial"
      class="
        vh-100
        d-flex
        align-items-center
        flex-column
        justify-content-center
      "
    >
      <h2 class="text-center">TUTORIAL</h2>
      <div class="mt-4">
        <router-link to="/" class="btn btn-white">Zurück</router-link>
      </div>
    </div>
    <div v-if="!ifPassedTutorial" class="mt-auto">
      <keep-alive>
        <component ref="currentStep" :is="steps[currentStep]"></component>
      </keep-alive>

      <div v-if="currentStep > 0" class="text-center">
        <div class="d-flex justify-content-center align-items-center pt-3 pb-5">
          <div
            v-for="(circle, index) in stepsTotal - 1"
            :key="index"
            class="circle"
            :class="{ active: index == currentStep - 1 }"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="currentStep > 0 && !ifPassedTutorial" class="text-center">
      <div class="d-flex justify-content-center align-items-center">
        <button class="btn btn-white mx-4" @click="goBack">Zurück</button>
        <button class="btn btn-secondary mx-4" @click="goNext">
          {{ isLastStep ? "Fertig" : "Weiter" }}
        </button>
      </div>
      <div
        @click="skipTutorial"
        class="link d-flex justify-content-end align-items-center"
      >
        <ph-arrow-right :size="20" weight="bold" />
        <p class="link pl-1">Überspringen</p>
      </div>
    </div>

    <div
      v-if="currentStep === 0 && !ifPassedTutorial"
      class="text-center mb-auto"
    >
      <button class="btn btn-white" @click="goNext">Starten</button>
      <div class="pt-5">
        <input
          v-model="skipNextTime"
          class="checkbox"
          type="checkbox"
          id="show_next_time"
          value="true"
        />
        <label for="show_next_time">Tutorial nicht mehr anzeigen</label>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialStart from "@/components/Tutorials/TutorialStart.vue";
import TutorialOne from "@/components/Tutorials/TutorialOne.vue";
import TutorialTwo from "@/components/Tutorials/TutorialTwo.vue";

export default {
  name: "Tutorials",
  components: {
    TutorialStart,
    TutorialOne,
    TutorialTwo,
  },
  data() {
    return {
      steps: ["TutorialStart", "TutorialOne", "TutorialTwo"],
      currentStep: 0,
      skipNextTime: false,
      ifPassedTutorial: false,
    };
  },
  watch: {
    skipNextTime(newVal) {
      newVal
        ? localStorage.setItem("ff_skip_tutorial", "true")
        : localStorage.setItem("ff_skip_tutorial", "false");
    },
  },
  computed: {
    stepsTotal() {
      return this.steps.length;
    },
    formInProgress() {
      return this.currentStep <= this.stepsTotal;
    },
    isLastStep() {
      return this.currentStep === this.stepsTotal - 1;
    },
  },
  methods: {
    goBack() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    goNext() {
      if (this.isLastStep) {
        this.skipTutorial();
      }
      this.goStepForward();
    },
    goStepForward() {
      this.currentStep++;
    },
    skipTutorial() {
      sessionStorage.setItem("ff_tutorial_passed", "true");
      this.$router.push({ name: "Home" });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.circle {
  width: 8px;
  height: 8px;
  margin: 4px;
  border-radius: 50%;
  opacity: 0.3;
  background: #ffffff;

  &.active {
    opacity: 1;
  }
}

.logo {
  height: 40px;
}
</style>
